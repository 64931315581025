import { Box, ListItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import CachedIcon from "@material-ui/icons/Cached";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuIcon from "@material-ui/icons/Menu";
import RepeatRoundedIcon from "@material-ui/icons/RepeatRounded";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import clsx from "clsx";
import Head from "next/head";
import React, { useCallback, useEffect, useMemo } from "react";
import { Banner } from "../components/Banner";
import { CurrentUser } from "../components/CurrentUser";
import { Link } from "../components/Link";
import { Loading } from "../components/Loading";
import { useAnalyticsContext } from "../context/AnalyticsContext";
import { AppContextActionType, useAppContext } from "../context/AppContext";
import { useUserContext } from "../context/UserContext";
import { useOurRouter } from "../hooks/useOurRouter";
import BufferIcon from "../img/buffer-icon2.svg";
import ChatIcon from "../img/chat-icon.svg";
import LogoOnBlack from "../img/logo-on-black.svg";
import LogoOnBlurple from "../img/logo-on-blurple.svg";
import LogoOnPink from "../img/logo-on-pink.svg";
import LogoSvg from "../img/Primary_FullColor.svg";
import SlackIcon from "../img/slack-icon.svg";
import VideoIcon from "../img/video-icon.svg";
import { Status } from "../subpages/status";

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: "grid",
      gridTemplateColumns: `auto`,
      gridTemplateRows: "min-content auto",
      gridTemplateAreas: '"header" "main"',
      height: "100%",
      width: "100%",
    },
    header: {
      gridArea: "header",
      position: "relative",
      zIndex: 10,
      "& $headerContainer": {
        display: "flex",
        alignItems: "center",
        maxWidth: "1292px",
        padding: theme.spacing(1, 3),
        [theme.breakpoints.up("sm")]: {
          padding: theme.spacing(0, 4),
        },
        [theme.breakpoints.up("lg")]: {
          padding: theme.spacing(0, 9.5),
        },
      },
    },
    main: {
      gridArea: "main",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    headerContainer: {},
    title: {
      maxWidth: "50%",
      "& :hover": {
        background: "transparent",
      },
    },
    logoBtn: {
      display: "block",
      maxWidth: 200,
      paddingLeft: theme.spacing(0.5),
      "&&:hover": {
        backgroundColor: "transparent",
      },
    },
    links: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
    link: {
      ...theme.typography.h1,
      color: theme.colors.white,
      display: "block",
      fontFamily: "Poppins",
      justifyContent: "flex-start",
      padding: theme.spacing(1, 2),
      [theme.breakpoints.up("md")]: {
        ...theme.typography.body1,
        color: theme.colors.logo.darkness,
        display: "flex",
        fontWeight: theme.typography.fontWeightRegular,
        letterSpacing: "normal",
        marginRight: theme.spacing(0.5),
        opacity: 1,
        padding: theme.spacing(1, 1.5),
        transition: "opacity .1s ease-in",
        "&$white": {
          color: theme.colors.white,
        },
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: theme.spacing(3),
      },
      "&:hover": {
        background: "none",
        cursor: "pointer",
      },
    },
    // dropdown menu
    menuList: {
      display: "none",
      margin: 0,
      padding: 0,
      [theme.breakpoints.up("md")]: {
        backgroundColor: "#2f2e41",
        borderRadius: 8,
        left: 0,
        padding: theme.spacing(1, 0),
        position: "absolute",
        top: 40,
      },
    },
    dropdownLink: {
      "&:hover ul": {
        display: "block",
      },
    },
    menuLink: {
      color: theme.palette.common.white,
      display: "flex",
      fontSize: ".75em",
      opacity: 1,
      padding: theme.spacing(1, 0),
      transition: "opacity .1s ease-in",
      "&:hover": {
        opacity: 0.8,
        textDecoration: "none",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1em",
        padding: theme.spacing(1, 3),
      },
    },
    logoSvg: {
      height: "auto",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        height: "4em",
        width: "8.4em",
      },
    },
    loginBtn: {
      borderColor: theme.palette.common.black,
      padding: theme.spacing(0.5, 1.5),
      "&$white": {
        borderColor: theme.colors.white,
        color: theme.colors.white,
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1.5, 4),
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: theme.spacing(1),
        minWidth: "120px",
      },
    },
    hamburger: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(-1),
      "&$white": {
        color: theme.colors.white,
      },
    },
    closeIcon: {
      color: theme.colors.white,
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(2),
      zIndex: 10,
    },
    carat: {
      marginLeft: theme.spacing(0.5),
    },
    featureIcon: {
      height: "24px",
      marginRight: theme.spacing(1.5),
      opacity: 0.8,
      width: "24px",
      [theme.breakpoints.up("md")]: {
        height: "18px",
        width: "18px",
      },
    },
    customIcon: {
      height: "20px",
      marginRight: 14,
      width: "20px",
      [theme.breakpoints.up("md")]: {
        height: "16px",
        width: "16px",
      },
    },
    white: {},
    navmenu: {
      display: "flex",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        alignItems: "flexStart",
        backgroundColor: theme.colors.logo.blurple,
        flexDirection: "column",
        height: "100vh",
        padding: theme.spacing(11, 2, 4),
      },
    },
  }),
  { index: 1, name: "LandingLayout" }
);

type LandingLayoutProps = {
  className?: string;
  title?: string;
  links?: React.ReactNode;
  loading?: boolean;
  hideNav?: boolean;
  logoColor?: string;
};

const LandingLayout: React.FC<LandingLayoutProps> = ({
  loading = false,
  hideNav = false,
  title,
  links,
  children,
  className,
  logoColor,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const router = useOurRouter();

  const [{ user, isAuthenticated }] = useUserContext();

  const {
    state: { title: docTitle },
    dispatch,
  } = useAppContext();

  const {
    state: { sentry },
  } = useAnalyticsContext();

  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const menuRef = React.useRef<HTMLButtonElement>(null);

  const handleSignupClick = useCallback(() => {
    const path = router.route;
    void router.push(path.startsWith("/login") ? "/signup" : "/login");
  }, [router]);

  const handleMenuToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const menuLinks = useMemo(
    () => (
      <MenuList className={classes.navmenu}>
        <ListItem
          className={clsx(
            classes.link,
            classes.dropdownLink,
            (logoColor === "blurple" || logoColor === "darkness") && classes.white
          )}
        >
          Features
          <KeyboardArrowDownIcon className={classes.carat} />
          <MenuList className={classes.menuList}>
            <ListItem
              button
              component="a"
              className={classes.menuLink}
              href="//reclaim.ai/features/habits/"
              target="reclaim"
            >
              <RepeatRoundedIcon className={classes.featureIcon} fontSize="small" />
              Habits
            </ListItem>
            <ListItem
              button
              component="a"
              className={classes.menuLink}
              href="//reclaim.ai/features/tasks/"
              target="reclaim"
            >
              <AssignmentTurnedInOutlinedIcon className={classes.featureIcon} />
              Tasks
            </ListItem>
            <ListItem
              button
              component="a"
              className={classes.menuLink}
              href="//reclaim.ai/features/smart-one-on-ones"
              target="reclaim"
            >
              <ChatIcon className={clsx(classes.featureIcon, classes.customIcon)} />
              Smart 1:1s
            </ListItem>
            <ListItem
              button
              component="a"
              className={classes.menuLink}
              href="//reclaim.ai/features/calendar-sync/"
              target="reclaim"
            >
              <CachedIcon className={classes.featureIcon} />
              Calendar Sync
            </ListItem>
            <ListItem
              button
              component="a"
              className={classes.menuLink}
              href="//reclaim.ai/features/buffer-time"
              target="reclaim"
            >
              <BufferIcon className={clsx(classes.featureIcon, classes.customIcon)} />
              Buffer Time
            </ListItem>
            <ListItem
              button
              component="a"
              className={classes.menuLink}
              href="//reclaim.ai/features/slack-integration/"
              target="reclaim"
            >
              <SlackIcon className={clsx(classes.featureIcon, classes.customIcon)} />
              Slack Integration
            </ListItem>
            <ListItem
              button
              component="a"
              className={classes.menuLink}
              href="//reclaim.ai/features/zoom-integration/"
              target="reclaim"
            >
              <VideoIcon className={clsx(classes.featureIcon, classes.customIcon)} />
              Zoom Integration
            </ListItem>
          </MenuList>
        </ListItem>
        <ListItem
          disableRipple
          className={clsx(classes.link, (logoColor === "blurple" || logoColor === "darkness") && classes.white)}
          button
          component="a"
          href="//reclaim.ai/use-cases/"
          title="Use Cases"
          target="reclaim"
        >
          Use Cases
        </ListItem>
        <ListItem
          disableRipple
          className={clsx(classes.link, (logoColor === "blurple" || logoColor === "darkness") && classes.white)}
          button
          component="a"
          href="//reclaim.ai/pricing/"
          title="Pricing"
          target="reclaim-help"
        >
          Pricing
        </ListItem>
        <ListItem
          disableRipple
          className={clsx(classes.link, (logoColor === "blurple" || logoColor === "darkness") && classes.white)}
          button
          component="a"
          href="//blog.reclaim.ai"
          title="Reclaim Blog"
          target="reclaim-blog"
        >
          Blog
        </ListItem>
      </MenuList>
    ),
    [
      classes.carat,
      classes.customIcon,
      classes.dropdownLink,
      classes.featureIcon,
      classes.link,
      classes.menuLink,
      classes.menuList,
      classes.navmenu,
      classes.white,
      logoColor,
    ]
  );

  const defaultLinks = useMemo(
    () => (
      <>
        <Hidden smDown>{menuLinks}</Hidden>
        {!!isAuthenticated && <CurrentUser withUserName={false} />}
        {!isAuthenticated && (
          <Button
            variant="outlined"
            title={router.pathname.startsWith("/login") ? "Sign up" : "Log in"}
            onClick={handleSignupClick}
            className={clsx(classes.loginBtn, (logoColor === "blurple" || logoColor === "darkness") && classes.white)}
          >
            {router.pathname.startsWith("/login") ? "Sign up" : "Log in"}
          </Button>
        )}
        <Hidden mdUp>
          <IconButton
            ref={menuRef}
            className={clsx(classes.hamburger, (logoColor === "blurple" || logoColor === "darkness") && classes.white)}
            aria-controls={menuOpen ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleMenuToggle}
          >
            <MenuIcon />
          </IconButton>
          <Drawer open={menuOpen} anchor="top">
            <IconButton
              ref={menuRef}
              className={classes.closeIcon}
              aria-controls={menuOpen ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleMenuToggle}
            >
              <CloseIcon />
            </IconButton>
            {menuLinks}
          </Drawer>
        </Hidden>
      </>
    ),
    [
      classes.closeIcon,
      classes.hamburger,
      classes.white,
      classes.loginBtn,
      handleSignupClick,
      isAuthenticated,
      menuLinks,
      menuOpen,
      router.pathname,
      logoColor,
    ]
  );

  useEffect(() => {
    dispatch({ type: AppContextActionType.Theme, payload: "light" });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: AppContextActionType.Title, payload: title });
  }, [dispatch, title]);

  return (
    <Box key="LandingLayout" className={clsx(classes.root, className)}>
      <Head>
        <title>{/^Reclaim/.test(docTitle) ? docTitle : `${docTitle} | Reclaim`}</title>
        <meta name="robots" content="all" key="robots" />
        <meta name="title" content={docTitle} />
        <meta name="og:title" content={docTitle} key="ogTitle" />
        <meta name="twitter:title" content={docTitle} key="twitterTitle" />
      </Head>

      {!!loading && <Loading />}
      {!loading && (
        <>
          <Box component="header" className={classes.header}>
            <Banner in size="big" variant="dark">
            🎉 Reclaim Team plans are here! -&nbsp;
              <Link href="/signup">Free 14-day trial</Link>
            </Banner>
            {!hideNav && (
              <Container className={classes.headerContainer}>
                <Box className={classes.title}>
                  <IconButton
                    className={classes.logoBtn}
                    component={Link}
                    href={!!user ? "/" : "//reclaim.ai"}
                    size="medium"
                    aria-label="reclaim.ai"
                    disableRipple
                    disableFocusRipple
                  >
                    {(() => {
                      switch (logoColor) {
                        case "shrimp":
                          return <LogoOnPink className={classes.logoSvg} />;
                        case "blurple":
                          return <LogoOnBlurple className={classes.logoSvg} />;
                        case "darkness":
                          return <LogoOnBlack className={classes.logoSvg} />;
                        default:
                          return <LogoSvg className={classes.logoSvg} />;
                      }
                    })()}
                  </IconButton>
                </Box>
                <Box className={classes.links}>{links || defaultLinks}</Box>
              </Container>
            )}
          </Box>

          <main className={classes.main}>
            <SentryErrorBoundary
              fallback={
                <Status
                  code={5000}
                  message="We are so sorry, but there seems to be an issue. Our engineers have been notified and are working on a fix."
                />
              }
              showDialog
              onError={() => {
                sentry?.addBreadcrumb({
                  category: "error",
                  message: "Page Error",
                  level: sentry.Severity.Critical,
                });
              }}
            >
              {children}
            </SentryErrorBoundary>
          </main>
        </>
      )}
    </Box>
  );
};

export default LandingLayout;
